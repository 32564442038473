@font-face {
  font-family: "HyundaiSansHead";
  src: url("../fonts/HyundaiSansHeadOffice-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "HyundaiSansHead";
  src: url("../fonts/HyundaiSansHeadOffice-Light.ttf");
  font-weight: lighter;
}
@font-face {
  font-family: "HyundaiSansHead";
  src: url("../fonts/HyundaiSansHeadOffice-Medium.ttf");
  font-weight: medium;
}
@font-face {
  font-family: "HyundaiSansHead";
  src: url("../fonts/HyundaiSansHeadOffice-Bold.ttf");
  font-weight: bold;
}
